import { reactive, readonly } from 'vue'
import apiService from '@/services/api.service'
import DocumentObject from '@/types/DocumentObject'
import unitStore from '@/stores/unit.store'
import { DocumentType } from '@/types/enums'
// @ts-ignore
import { v4 as uuidv4 } from 'uuid'
import ApiError from '@/types/ApiError'
import appStore from '@/stores/app.store'
import b2buserStore from '@/units/b2b/stores/b2buser.store'

interface DocumentsStoreState {
  documents: Array<DocumentObject>
  sessionDocuments: Array<DocumentObject>
  documentsLoaded: boolean
  possibleDocumentCategories: Array<string>
}

// Create store for account data from api-endpoint /account
const state = reactive({
  documents: [],
  sessionDocuments: [],
  documentsLoaded: false,
  possibleDocumentCategories: [],
}) as DocumentsStoreState

const setPossibleCategories = (categories: Array<string>) => {
  console.log('setPossibleCategories', categories)
  state.possibleDocumentCategories = categories
}

const addPossibleCategory = (category: DocumentType) => {
  state.possibleDocumentCategories.push(category)
}

const fetchDocuments = async () => {
  if (appStore.state.isB2b) {
    return await apiService.documents
      .getB2b(b2buserStore.state.currentCompany.id)
      .then((result) => {
        state.documents = result.data.documents
      })
  } else {
    return await apiService.documents
      .get(unitStore.state.activeUnitId)
      .then((result) => {
        state.documents = result.data.documents
      })
  }
}

const hasDocuments = (category: string | null, type?: string) => {
  let docArray = state.documents.filter((doc) => doc.category === category)
  if (type) {
    docArray = state.documents.filter((doc) => doc.type === type)
  }
  return docArray.length >= 1
}

const getDocumentsByCategory = (category: string | null) => {
  return state.documents.filter((doc) => doc.category === category)
}

const addDocument = async (
  file: File,
  category: string,
  sessionMode = false,
  type?: string,
) => {
  const documentObject = {
    uuid: uuidv4(),
    category: category,
    name: file.name,
    file: file,
    type: type,
  } as DocumentObject
  state[sessionMode ? 'sessionDocuments' : 'documents'].push(documentObject)
}

const setDocumentNameAndDateByUuid = (
  uuid: string,
  name: string,
  createdAt: string,
) => {
  let fileObj = state.documents.filter((doc) => doc.uuid === uuid)[0]

  if (!fileObj) {
    fileObj = state.sessionDocuments.filter((doc) => doc.uuid === uuid)[0]

    if (!fileObj) {
      console.debug(
        '[documentsStore] error get uploaded item',
        uuid,
        state.documents,
      )
      return
    }
  }
  fileObj.name = name
  fileObj.createdAt = createdAt
}

const setDocumentCategoryAndType = async (
  id: string,
  newCategory: string,
  newType?: string,
) => {
  const fileObj = state.documents.filter((doc) => doc.uuid === id)[0]

  if (!fileObj.uuid) {
    return
  }

  const oldCategory = fileObj.category
  const oldType = fileObj.type

  fileObj.category = newCategory
  fileObj.type = newType

  return await apiService.documents
    .patch(fileObj.uuid, newCategory, newType)
    .then(
      (result) => {
        // console.log('setDocumentCategoryAndType result', result.data)
      },
      (error: ApiError) => {
        console.debug(
          '[documentsStore] error while edit categorry and type:',
          error,
        )
        fileObj.category = oldCategory
        fileObj.type = oldType
      },
    )
}

const setDocumentsLoaded = (value: boolean) => {
  state.documentsLoaded = value
}

const removeDocumentById = (id: string) => {
  state.sessionDocuments = state.sessionDocuments.filter(
    (doc) => doc.uuid !== id,
  )
  state.documents = state.documents.filter((doc) => doc.uuid !== id)
}

const removeDocumentByObj = (documentObj: DocumentObject) => {
  state.sessionDocuments = state.sessionDocuments.filter(
    (doc) => doc.file !== documentObj.file,
  )
  state.documents = state.documents.filter(
    (doc) => doc.file !== documentObj.file,
  )
}

export default {
  state: readonly(state),
  setPossibleCategories,
  addPossibleCategory,
  fetchDocuments,
  addDocument,
  setDocumentNameAndDateByUuid,
  setDocumentCategoryAndType,
  setDocumentsLoaded,
  removeDocumentById,
  removeDocumentByObj,
  hasDocuments,
  getDocumentsByCategory,
}
